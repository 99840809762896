import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import EventProvider from "./contexts/EventProvider";
import './outpout.css';
import Home from './components/Home';
import EventLandingPage from './components/EventLandingPage';
import CollectifAnnuaire from './components/CollectifAnnuaire'
import Whois from './components/Whois'
import News from './components/News'
import Calendar from './components/Calendar'
import Whatis from './components/Whatis'
import ProgrammesInnovation from "./components/ProgrammesInnovation";
import CompanyList from "./components/CompanyList";
import Counter from "./components/Counter";
import CoordinateursCollectifs from "./components/CoordinateursCollectifs";
import Programm from "./components/Programm";
import ProgrammDetails from "./components/ProgrammDetails";
import ProgrammPlaces from "./components/ProgrammPlaces";
import DemoList from "./components/DemoList";
import DemoDetails from "./components/DemoDetails";
import Palmares from "./components/Palmares";
import Candidats from "./components/Candidats";
import Candidats2 from "./components/Candidats2";
import Places from "./components/Places";
import Login from "./components/Login";
import LoginRedirect from "./components/account/LoginRedirect";
import MapSmarter from './components/MapSmarter'
import DeparturesAll from "./components/Broadcast/DeparturesAll.js";
import Departure from "./components/Broadcast/Departure.js";

import EventDescription from "./components/EventDescription";
import CommiteOrganisation from "./components/CommiteOrganisation";
import PartenaireList from "./components/PartenaireList";
import JuryRegistration from "./components/JuryRegistration";
import PitchRegistration from "./components/PitchRegistration";
import ConnectedStudios from "./components/ConnectedStudios";
import LeadingEvents from "./components/LeadingEvents";
import CommiteOrganisationEvent from "./components/CommiteOrganisationEvent";
import PressPartenaireEvent from "./components/PressPartenaireEvent";
import PressEvent from "./components/PressEvent";
import InfosPratiques from "./components/InfosPratiques";
import Speakers from "./components/Speakers";
import CollectifDetails from "./components/CollectifDetails";
import Replay from "./components/Replay";
import ReplayDetails from "./components/ReplayDetails";
import Profile from "./components/Profile";
import ConferenceDetails from "./components/ConferenceDetails";
import Archives from "./components/Archives";
import ParcoursDetails from "./components/ParcoursDetails";
import Registration from "./components/Registration";
import TestSplide from "./components/TestSplide";
import CollectifCandidat from "./components/CollectifCandidat";
import ProgrammesPlansAction from "./components/ProgrammesPlansAction";
import UserProvider from "./contexts/UserProvider";
import PrivateRoute from './components/PrivateRoute';
import PlanAction from './components/PlanAction';
import TempsForts from './components/TempsForts';
import DemonstrateurPresentation from './components/DemonstrateurPresentation';
import EventComponent from './components/EventComponent';

import Texploration from './components/account/Techxploration';

import UserProfile from "./components/account/UserProfile";
import UserDemoList from "./components/account/UserDemoList"
import UserDemoDetails from "./components/account/UserDemoDetails"
import UserProgrammes from "./components/account/UserProgrammes"
import UserProgrammeEdit from "./components/account/UserProgrammeEdit.js"
import UserEvents from "./components/account/UserEvents";
import PrestaByUserProgramme from "./components/account/PrestaByUserProgramme";
import ThematiquesByUserProgramme from "./components/account/ThematiquesByUserProgramme";
import AllProgramms from "./components/account/AllProgramms";
import UserCycleLang from "./components/account/UserCycleLang";

import Page from "./components/Page";
import Networking from "./components/account/Networking";
import UserCycleLangDetails from "./components/account/UserCycleLangDetails";
import UserEvent from "./components/account/UserEvent";
import RegistrationConfirmation from "./components/RegistrationConfirmation";
import UserEventJuryEvents from "./components/account/UserEventJuryEvents";
import EventCycleLangList from "./components/EventCycleLangList";
import EventCycleLangDetail from "./components/EventCycleLangDetail";
import Server from "./components/Broadcast/Server.js";
import TechxplorationList from "./components/account/Techxploration/TechxplorationList";
import UserContacts from "./components/account/UserContacts";
import Schedule from "./components/account/Schedule";
import ScheduleSelection from "./components/account/ScheduleSelection";

import LinkedInAuth from './components/account/LinkedInAuth';
import LinkedInCallback from './components/account/LinkedInCallback';


import Settings from "./components/account/Settings";
import PasswordRecovery from "./components/PasswordRecovery";
import Ecosystem from "./components/Ecosystem";
import EventCycleLangListGeo from "./components/EventCycleLangListGeo";
import EcosystemContactList from "./components/EcosystemContactList";
import EventCycleLangDetail2 from "./components/EventCycleLangDetail2";
import Contributions from "./components/Contributions";
import darkModeContext from "./contexts/DarkModeContext";


import { useContext } from "react";
import LinkedInRedir from "./components/account/LinkedInRedir.js";
import BecomeCandidate from "./components/BecomeCandidate.js";
import PaysRecap from "./components/PaysRecap.js";
import PaysRecapDetails from "./components/PaysRecapDetails.js";
import ProgrammItemHilight from "./components/ProgrammItemHilight.js";
import PortailProgramm from "./components/PortailProgramm.js";
import Soirees from "./components/Soirees.js";
import Pitch from "./components/account/Pitch.js";
import Attentes from "./components/account/Attentes.js";
import Pricing from "./components/Pricing.js";
import Investisseurs from "./components/Investisseurs.js";
import Highligths from "./components/Highligths.js";
import DashboardVote from "./components/account/DashboardPitch.js";
import DashboardPitch from "./components/account/DashboardPitch.js";
import Annuaire from "./components/account/Annuaire.js";
import AccountPalmares from "./components/account/AccountPalmares.js";
import Store from "./components/Store.js";
import RegistrationProfile from "./components/RegistrationProfile.js";
import ProgrammeThematiques from "./components/ProgrammeThematiques.js";
import LeadershipTeam from "./components/LeadershipTeam.js";
import EventCoordinateursCollectifs from "./components/EventCoordinateursCollectifs.js";
import EventCoordinateursCollectifsDetails from "./components/EventCoordinateursCollectifsDetails.js";
import CheckoutResult from "./components/CheckoutResult.js";
import CandidatsAll from "./components/CandidatsAll.js";
import NotFound from "./components/NotFound.js";
import UserEventRoles from "./components/account/UserEventRoles.js";
import testWatch from "./components/TestWatch.js";
import TestWatch from "./components/TestWatch.js";
import CoordinateurGeographiqueTools from "./components/CoordinateurGeographiqueTools.js";
import Partenaires from "./components/Partenaires.js";

function App() {

  const { darkMode } = useContext(darkModeContext);

  //let { idEvent } = useParams();

  //  console.log(idEvent);

  return (

    <UserProvider>
      <EventProvider>
        {darkMode}
        <div className={`App h-full ${darkMode ? 'bg-neutral-950' : ''}`}>

          <Router>



            <Switch>

              <Route path="/about" component={Home} />
              <Route path="/events/:idEvent/pricing" component={Pricing} />
              <Route path="/events/:idEvent/programm/:id_presta/demo-details" component={DemoDetails} />
              <Route path="/events/:idEvent/programm/:id_conf_event/programm-details" component={ProgrammDetails} />
              <Route path="/events/:idEvent/programm/:id_conf_event/parcours-details" component={ParcoursDetails} />
              <Route path="/events/test" component={TestSplide} />
              <Route path="/events/:idEvent/profile/:id_contact" component={Profile} />
              <Route path="/events/:idEvent/conference-details/:id_conference" component={ConferenceDetails} />
              <Route path="/events/:idEvent/commite-organisation-event" component={CommiteOrganisationEvent} />
              <Route path="/events/:idEvent/speakers" component={Speakers} />

              <Route path="/events/:idEvent/coordinateurs-collectif/:idCycleLang" component={EventCoordinateursCollectifsDetails} />

              <Route path="/events/:idEvent/annuaire-jury">
                <Speakers
                  pageTitle="Les membres du jury"
                  statutsList={['jury', 92, 204, 263, 124, 92]} />
              </Route>
              <Route path="/events/:idEvent/annuaire-techxplorateurs">
                <Speakers
                  pageTitle="Ils coordonnent les Tech'xplorations"
                  statutsList={['coordinateur_techxploration', 'techxplorateur', 'coordinateur-techxploration-onboarding', '237', '234', '237', "240"]} />
              </Route>

              <Route path="/events/:idEvent/programm/:programmType" component={Programm} />
              <Route path="/events/:idEvent/programm" component={Programm} />

              <Route path="/events/:idEvent/commite-organisation-event" component={CommiteOrganisationEvent} />
              <Route path="/events/:idEvent/partenaires" component={Partenaires} />
              <Route path="/events/:idEvent/partenaire-list" component={PartenaireList} />
              <Route path="/events/:idEvent/press-event" component={PressEvent} />
              <Route path="/events/:idEvent/press-partenaire-event" component={PressPartenaireEvent} />

              <Route exact path="/events/:idEvent/signup/:formName/collectif/:id_cycle_lang/c/:idContact" component={PitchRegistration} />
              <Route exact path="/signup/:formName/collectif/:id_cycle_lang/c/:idContact" component={PitchRegistration} />
              <Route exact path="/events/:idEvent/signup/:formName/c/:idContact" component={PitchRegistration} />
              <Route exact path="/signup/:formName/c/:idContact" component={PitchRegistration} />
              <Route exact path="/events/:idEvent/signup/:formName/collectif/:id_cycle_lang" component={PitchRegistration} />
              <Route exact path="/events/:idEvent/signup/:formName/ecl/:id_event_cycle_lang" component={PitchRegistration} />
              <Route exact path="/events/:idEvent/signup/:formName/ecl/:id_event_cycle_lang/c/:idContact" component={PitchRegistration} />
              <Route exact path="/signup/:formName/collectif/:id_cycle_lang" component={PitchRegistration} />
              <Route path="/events/:idEvent/signup/:formName" component={PitchRegistration} />
              <Route exact path="/signup/:formName/:coordinateur" component={PitchRegistration} />
              <Route path="/signup/:formName/" component={PitchRegistration} />

              <Route path="/events/:idEvent/pitch-registration" component={PitchRegistration} />
              <Route path="/events/:idEvent/registration-confirmation/:token" component={RegistrationConfirmation} />
              <Route path="/events/:idEvent/event-description" component={EventDescription} />
              <Route path="/events/:idEvent/infos-pratiques" component={ProgrammPlaces} />
              <Route path="/events/:idEvent/programm-places" component={ProgrammPlaces} />
              <Route path="/events/:idEvent/replay/:id_video/replay-details" component={ReplayDetails} />
              <Route path="/events/:idEvent/replay" component={Replay} />
              <Route path="/events/:idEvent/candidats2" component={Candidats2} />
              <Route path="/events/:idEvent/candidats" component={Candidats} />
              <Route exact path="/events/:idEvent/candidats-all/:country" component={CandidatsAll} />
              <Route path="/events/:idEvent/candidats-all" component={CandidatsAll} />
              <Route path="/events/:idEvent/places" component={Places} />
              <Route exact path="/events/:idEvent/palmares" component={Palmares} />
              <Route exact path="/events/:idEvent/become-candidate" component={BecomeCandidate} />
              <Route exact path="/events/:idEvent/pays-recap/" component={PaysRecap} />
              <Route exact path="/events/:idEvent/pays-recap/:id_cycle_lang" component={PaysRecapDetails} />

              <Route exact path="/events/:idEvent/palmares-all">
                <Palmares getAllCandidats />
              </Route>

              <Route exact path="/programmes-innovation" component={ProgrammesInnovation} />
              <Route exact path="/programmes-plans-action" component={ProgrammesPlansAction} />



              <Route exact path="/events/:idEvent/programme-thematiques" component={ProgrammeThematiques} />
              <Route path="/events/:idEvent/registration" component={RegistrationProfile} />
              <Route path="/events/:idEvent/signup" component={Registration} />
              <Route path="/events/:idEvent/page/:idPage" component={Page} />
              <Route path="/events/:idEvent/login" component={Login} />
              <Route path="/events/:idEvent/temps-forts" component={TempsForts} />
              <Route path="/events/:idEvent/highligths/:destinationList" component={Highligths} />
              <Route path="/events/:idEvent/demonstrateur-presentation" component={DemonstrateurPresentation} />
              <Route path="/events/:idEvent/event-component/:idEventComponent" component={EventComponent} />
              <Route path="/events/:idEvent/cl/:id_event_cycle_lang" component={EventCycleLangDetail} />
              <Route exact path="/events/:idEvent/ecl/:idEventCycleLang" component={EventCycleLangDetail2} />
              <Route exact path="/events/:idEvent/ecl/" component={EventCycleLangList} />
              <Route path="/events/:idEvent/ecl-geo/" component={EventCycleLangListGeo} />
              <Route path="/events/:idEvent/ecosystem/" component={Ecosystem} />
              <Route path="/events/:idEvent/ecosystem-contact-list/:idEventComposant" component={EcosystemContactList} />
              <Route path="/events/:idEvent/ecosystem-contact-list/" component={EcosystemContactList} />
              <Route path="/events/:idEvent/contributions/" component={Contributions} />
              {/* <Route path="/events/:idEvent/store/:listProductId" component={Store} /> */}
              <Route path="/events/:idEvent/store/:idEventCycleLang" component={Store} />
              <Route path="/events/:idEvent/store/" component={Store} />
              <Route path="/events/:idEvent/login-redirect" component={LoginRedirect} />
              <Route path="/events/:idEvent/soirees" component={Soirees} />


              <Route path="/events/:idEvent/coordinateurs-collectif" component={EventCoordinateursCollectifs} />
              <Route path="/events/:idEvent/coordinateur-geographique-tools/:idContact" component={CoordinateurGeographiqueTools} />
              <Route path="/events/:idEvent/coordinateur-geographique-tools" component={CoordinateurGeographiqueTools} />
              <Route path="/events/:idEvent" component={EventLandingPage} />



              <Route path="/login" component={Login} />
              <Route path="/checkout-result/" component={CheckoutResult} />

              <Route path="/login-redirect/:redirectUrl" component={LoginRedirect} />
              <Route path="/login-redirect" component={LoginRedirect} />
              <Route path="/partenaires-presse">
                <CompanyList pageTitle="Partenaires média" statutList={['partenaire_media']} />
              </Route>
              <Route path="/profile/:id_contact" component={Profile} />
              <Route path="/commite-organisation" component={CommiteOrganisation} />
              <Route exact path="/coordinateurs" component={CoordinateursCollectifs} />
              <Route exact path="/counter" component={Counter} />
              <Route exact path="/calendar"><Calendar eventFormatFilter={['1', '2', '3', '5']} /></Route>
              <Route exact path="/pre-selection-calendar"><Calendar eventFormatFilter={['6']} /></Route>
              <Route exact path="/connected-studios" component={ConnectedStudios} />
              <Route exact path="/leading-events" component={LeadingEvents} />
              <Route exact path="/affiliated-events" component={<Calendar />} />
              <Route exact path="/whois" component={Whois} />
              <Route exact path="/investisseurs" component={Investisseurs} />
              <Route exact path="/palmares" component={Palmares} />
              <Route path="/programm/:programmType" component={PortailProgramm} />
              <Route exact path="/whatis" component={Whatis} />
              <Route exact path="/News" component={News} />
              <Route exact path="/archives" component={Archives} />
              <Route exact path="/collectif-categorie/:cycle_lang_type/:id_cycle_lang" component={CollectifDetails} />
              <Route exact path="/collectif-categorie/:cycle_lang_type" component={CollectifAnnuaire} />

              <Route exact path="/leadership-team" component={LeadershipTeam} />
              <Route exact path="/password-recovery" component={PasswordRecovery} />

              <PrivateRoute exact path="/account/profil/" component={UserProfile} />
              <PrivateRoute exact path="/account/networking/" component={Networking} />
              <PrivateRoute exact path="/account/attentes/" component={Attentes} />


              <PrivateRoute exact path="/account/event/:idEvent/techxploration/my-techxplorations" component={TechxplorationList} />
              <PrivateRoute exact path="/account/event/:idEvent/techxploration" component={Texploration} />
              <PrivateRoute exact path="/account/event/:idEvent/pitch/" component={Pitch} />
              <PrivateRoute exact path="/account/event/:idEvent/schedule/" component={Schedule} />
              <PrivateRoute exact path="/account/event/:idEvent/schedule-selection/" component={ScheduleSelection} />
              <PrivateRoute exact path="/account/event/:idEvent/parcours" component={UserEventJuryEvents} />
              <PrivateRoute exact path="/account/event/:idEvent/dashboard-pitch" component={DashboardPitch} />
              <PrivateRoute exact path="/account/event/:idEvent/annuaire/:annuaireFilter" component={Annuaire} />
              <PrivateRoute exact path="/account/event/:idEvent/roles" component={UserEventRoles} />
              <PrivateRoute exact path="/account/events/" component={UserEvents} />
              <PrivateRoute exact path="/account/event/:idEvent" component={UserEvent} />
              <PrivateRoute exact path="/account/event/:idEvent/palmares" component={AccountPalmares} />
              <PrivateRoute exact path="/account/contacts/" component={UserContacts} />
              <PrivateRoute exact path="/account/settings/" component={Settings} />
              <PrivateRoute exact path="/account/programmes/" component={UserProgrammes} />
              <PrivateRoute exact path="/account/programmes/:idProgramme" component={UserProgrammeEdit} />
              <PrivateRoute exact path="/account/demos/" component={UserDemoList} />
              <PrivateRoute exact path="/account/demos/:idPresta" component={UserDemoDetails} />
              <PrivateRoute exact path="/account/presta-by-user-programme" component={PrestaByUserProgramme} />
              <PrivateRoute exact path="/account/thematiques-by-user-programme" component={ThematiquesByUserProgramme} />
              <PrivateRoute exact path="/account/all-programms" component={AllProgramms} />
              <PrivateRoute exact path="/account/my-programms" component={PrestaByUserProgramme} />
              <PrivateRoute exact path="/account/my-collectives" component={UserCycleLang} />
              <PrivateRoute exact path="/account/collectif/:idCycleLang" component={UserCycleLangDetails} />

              <Route path="/linkedin-auth" component={LinkedInAuth} />
              <Route path="/linkedin-callback" component={LinkedInCallback} />
              <Route path="/linkedin-redir" component={LinkedInRedir} />

              <Route exact path="/planaction/:idCycleLang/:composant" component={PlanAction} />

              <Route exact path="/broadcast/server" component={Server} />


              <Route path="/test-watch" component={TestWatch} />
              <Route exact path="/broadcast/departures-all-nofilter/:idEvent">
                <DeparturesAll
                  title={<><span className='text-blue-700'>Parcours de visite</span>   <span className='text-slate-700 '> Prochains départs</span> </>}
                />
              </Route>
              <Route exact path="/broadcast/departures-all/:idEvent">
                <DeparturesAll
                  title={<><span className='text-blue-700'>Parcours de visite</span>   <span className='text-slate-700 '> Prochains départs</span> </>}
                  filter />
              </Route>

              <Route path="/map">
                <MapSmarter />
              </Route>

              <Route exact path="/broadcast/planning-pitch/:idEvent">
                <DeparturesAll
                  title="Prochaines sessions de pitch"
                  filter idConfEventTypeList={["67", "88", "74"]} />
              </Route>

              <Route exact path="/broadcast/departure-nofilter/:idEvent">
                <Departure />
              </Route>
              <Route exact path="/broadcast/departure/:idEvent">
                <Departure filter />
              </Route>

              <Route exact path="/" component={Home} />

              <Route path="/" component={NotFound} />
              {
                //else 404


              }
            </Switch>

          </Router>

        </div>
      </EventProvider>
    </UserProvider>
  )
}

export default App;
