import React, { useEffect, useState, useContext } from 'react';
import RegistrationFormSection from './RegistrationFormSection';
import parse from 'html-react-parser'
import { useFormContext } from 'react-hook-form';
import EventContext from '../contexts/EventContext'
import Moment from 'react-moment';
import moment from 'moment';
import "moment/locale/fr";
import { useTranslation } from 'react-i18next';
import useApiUrlTranslation from './useApiUrlTranslation';
import { removeDuplicateObjectFromArray, sortByAttribute } from '../utils';

const RegistrationFormParcoursThematiques = ({ title, confEventTypeList, instructions, isThematiques,

    parcoursThematiquesSelection, setParcoursThematiquesSelection }) => {

    const { register } = useFormContext();

    const [nouveauCreneau, setNouveauCreneau] = useState({});

    const [selectedValue, setSelectedValue] = useState();

    const [optgroupList, setOptgroupList] = useState([]);

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [confEventList, setConfEventList] = useState([]);

    const [confEventListAll, setConfEventListAll] = useState([]);

    const { i18n, t, apiUrlTranslation } = useApiUrlTranslation()

    const handleUpdateNouveauCreneau = (e) => {

        e.preventDefault();

        setNouveauCreneau({ ...nouveauCreneau, [e.currentTarget.name]: JSON.parse(e.currentTarget.value) })

        setSelectedValue(null);
    }

    const clearSelectedValue = () => {

        setSelectedValue(false);
    }


    const handleAjouterCreneaux = (e) => {

        e.preventDefault();

        const newCreneau = [...parcoursThematiquesSelection, nouveauCreneau];

        setParcoursThematiquesSelection(newCreneau)

        clearSelectedValue(null)

        /*   setMaSelection(JSON.stringify(
               newCreneau.map(item => {
                   return { id_conf_event: item.creneau.id_conf_event, id_jury_event_model: item.parcours.jury_event.id_jury_event }
               })
           ))
   */
    }

    const handleDeleteCreneau = (e) => {

        const currentIndex = e.currentTarget.getAttribute('index');

        e.preventDefault();

        let newCreneau = [...parcoursThematiquesSelection]

        newCreneau = newCreneau.filter((item, index) => index != currentIndex);

        setParcoursThematiquesSelection(newCreneau)
    }

    useEffect(() => {

        const fetchConfEventList = async () => {

            await fetch(`${apiUrlTranslation}?action=getConfEvent&id_event= ${currentEvent.id_event}`)
                .then(res => res.json())
                .then(res => {

                    //const filteredList = res.filter(confEvent => confEvent.publier != 'n' && confEventTypeList.includes(confEvent.type))
                    const filteredList = res.filter(confEvent => confEvent.publier != 'n' && confEventTypeList.includes(confEvent.type))
                    setConfEventList(filteredList);
                    setConfEventListAll(res);

                    const newOptgroupList = res.filter(item => item.type == '87').map(confEvent => {
                        return {
                            conf_event_date: confEvent.conf_event_date,
                            type: confEvent.type
                        }
                    })

                    setOptgroupList(
                        removeDuplicateObjectFromArray(
                            sortByAttribute(newOptgroupList, 'conf_event_date'), 'conf_event_date'));
                })
        }

        currentEvent && fetchConfEventList();

    }, [currentEvent, i18n.resolvedLanguage]);

    if (confEventList.length === 0) {

        return '';
    }

    return <RegistrationFormSection
        title={title}
        instructions={instructions}>

        <div id="components-demo">
            <div>
                <div class="row">

                    {
                        <input
                            type="hidden"
                            {...register("parcoursThematiques", {
                                onChange: (e) => console.log(e)
                            })}
                            value={JSON.stringify(parcoursThematiquesSelection.map(item => {
                                return { id_conf_event: item.creneau.id_conf_event, id_jury_event_model: item.parcours.jury_event.id_jury_event }
                            }))} />
                    }

                    <div class="col">
                        <select
                            id="creneau"
                            name="creneau"
                            className="form-select capitalize"
                            onChange={(e) => handleUpdateNouveauCreneau(e)}
                            value={selectedValue}>

                            <option value="" selected="selected">{t('form.sections.parcours_thematiques.creneaux')}</option>
                            { /* .filter(jour => jour.jury_event.thematique != "y" && ["87"].includes(jour.type)) */}

                            {confEventListAll && optgroupList &&
                                // removeDuplicateObjectFromArray(confEventListAll, 'conf_event_jour')
                                optgroupList
                                    .map(jour =>
                                        <optgroup label={`${moment(jour.conf_event_date).format("LL")}`}  >

                                            {confEventListAll && confEventListAll
                                                .filter(conf_event => ["87"].includes(conf_event.type) && jour.conf_event_date == conf_event.conf_event_date)
                                                .filter(conf_event => {

                                                    //masquer les créneauw sélectionnés
                                                    const selection = parcoursThematiquesSelection.map(item => item.creneau.id_conf_event);
                                                    return !selection.includes(conf_event.id_conf_event);
                                                })
                                                .map(jour =>
                                                    <option id_conf_event={jour.id_conf_event} value={JSON.stringify(jour)}>{jour.heure_debut}-{jour.heure_fin}</option>)}

                                        </optgroup>)}


                            { /* confEventListAll && confEventListAll
                                .filter(conf_event => ["87"].includes(conf_event.type))
                                .filter(conf_event => {

                                    //masquer les créneauw sélectionnés
                                    const selection = parcoursThematiquesSelection.map(item => item.creneau.id_conf_event);
                                    return !selection.includes(conf_event.id_conf_event);
                                })
                                .map(item =>
                                    <option value={JSON.stringify(item)}>{item.conf_event_date},  {item.heure_debut}-{item.heure_fin} </option>)
                                */}

                            {/*                      
                            <optgroup label="Mercredi 15 Septembre">
                                <option value="2021-09-15 15:00-16:00" style={{ display: "none" }}> 15:00-16:00</option> -</optgroup>
                            <optgroup label="Vendredi 25 Mars">
                                <option value="2022-03-25 15:00-16:00"> 15:00-16:00</option> -
                            </optgroup>
                            */ }
                        </select>
                    </div>
                    <div class="col">
                        <select
                            name="parcours"
                            id="parcours"
                            className="form-select"
                            onChange={(e) => handleUpdateNouveauCreneau(e)}
                        >
                            <option value="" selected="selected">{t('form.sections.parcours_thematiques.selection_thematique')}</option>

                            {
                                //
                                confEventList && confEventList.filter(item => item.jury_event.thematique == "y").map(item =>
                                    <option value={JSON.stringify(item)}> {parse(item.conf_event_lang.cel_titre)}</option>)}
                        </select>
                    </div>
                    <div class="col">
                        <button

                            class={`btn btn-primary btn-sm
                            ${!nouveauCreneau.creneau || !nouveauCreneau.parcours ? "disabled" : ""} 
                            `} onClick={(e) => handleAjouterCreneaux(e)}>{t('form.sections.parcours_thematiques.ajouter')}</button>

                    </div></div></div>
            <input type="hidden" id="parcours_selection" value="[{&quot;creneau&quot;:&quot;2021-09-15 15:00-16:00&quot;,&quot;parcours&quot;:&quot;4258&quot;,&quot;parcours_nom&quot;:&quot;Plus Robotisé&quot;}]" />
            <table class="table table-bordered table-sm table-striped mt-5">
                <thead className="capitalize">
                    <tr>
                        <th style={{ width: "20px" }}></th>
                        <th>{t('form.sections.parcours_thematiques.creneaux')}</th> <th>{t('form.sections.parcours_thematiques.parcours')}</th>
                    </tr>
                </thead>
                <tbody>
                    {parcoursThematiquesSelection && parcoursThematiquesSelection.map((item, index) => <tr key={index}>
                        <td><button class="btn btn-secondary btn-sm" index={index} onClick={(e) => handleDeleteCreneau(e)}>X</button></td>
                        <td>{item.creneau.conf_event_date} {item.creneau.heure_debut} {item.creneau.heure_fin}</td>
                        <td>{item.parcours.conf_event_lang.cel_titre}</td>
                    </tr>)}

                </tbody>
            </table>
        </div>
    </RegistrationFormSection>
}

export default RegistrationFormParcoursThematiques;
