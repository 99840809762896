import React from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import RegistrationFormRow from './RegistrationFormRow'

//demande_referencement_cycle_lang_is_visible 

const RegistrationFormDemandeReferencementCycleLang = ({ formulaire }) => {
    return (
        <RegistrationFormSection
            title={formulaire.demande_referencement_cycle_lang_section_title}
            instructions={formulaire.demande_referencement_cycle_lang_section_instructions}>

            <RegistrationFormRow name='nom_cycle_lang' placeholder="Nom" />

            <RegistrationFormRow

                isRegister
                inputType="textarea" name='cycle_lang_descriptif' placeholder=" Descriptif " />

            {/* <RegistrationFormRow
                inputType="file"
                name='demande_referencement_cycle_lang_visuel'
                placeholder="Logo / visuel"
                required={false}
                accept={[
                    '.jpeg',
                    '.png',
                    '.jpg'
                ]} /> */}

        </RegistrationFormSection>
    )
}

export default RegistrationFormDemandeReferencementCycleLang