import parse from 'html-react-parser';
import React, { useState, useEffect, useContext, useRef } from 'react';
import Loader from './Loader';
import { API_URL } from '../config';
import EventContext from '../contexts/EventContext';
import Layout from './Layout';
import { removeDuplicateObjectFromArray, sortByAttribute } from '../utils';
import useApiUrlTranslation from './useApiUrlTranslation';
import { Link } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { ClipboardDocumentCheckIcon, PaperAirplaneIcon, ClipboardIcon, LinkIcon } from '@heroicons/react/24/outline';
import { use } from 'i18next';
import HTMLReactParser from 'html-react-parser';


const Article = ({ eventCycleLang, presta_type, getEventCycleLangPrestaType }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();


    const [prixFinal, setPrixFinal] = useState(Number(presta_type.montant_ht_part_mlg) + Number(presta_type.montant_ht_retrocession_partenaire));

    const [retrocession, setRetrocession] = useState(presta_type.montant_ht_retrocession_partenaire);

    const updateEventPresta = async ({
        prix_unitaire_ht,
        montant_ht_retrocession_partenaire
    }) => {

        await fetch(`${API_URL}?action=updatePrestaType&get_req=y`, {

            method: 'POST',

            body: JSON.stringify({
                id_presta_type: presta_type.id_presta_type,
                montant_ht_retrocession_partenaire: montant_ht_retrocession_partenaire,
                prix_unitaire_ht: prix_unitaire_ht
            })
        })
            //.then(res => res.json())
            .then(res => {
                getEventCycleLangPrestaType()
            })
    }



    const handleRetrocession = (e) => {

        const retrocession = e.target.value;

        const newFinalPrice = Number(presta_type.montant_ht_part_mlg) + Number(retrocession);

        if (retrocession) {
            setPrixFinal(newFinalPrice)
        }

        if (newFinalPrice == '') {

            setPrixFinal();
        }

        updateEventPresta({
            prix_unitaire_ht: newFinalPrice,
            montant_ht_retrocession_partenaire: retrocession

        })

        console.log(presta_type.prix_unitaire_ht)
    }

    return (
        <tr className="divide-x ">
            <td className="text-left ">

                <img className="w-10 mx-4" src={presta_type.visuels.small} alt="" />
            </td>
            <td className="text-left text-sm mx-2">{presta_type.presta_type_nom}</td>
            <td className="">{presta_type.prix_unitaire_ht_barre}</td>
            <td>{presta_type.montant_ht_part_mlg}</td>
            <td className="py-2 mx-2">
                <input

                    min="0"
                    defaultValue={presta_type.montant_ht_retrocession_partenaire}
                    className="border rounded py-2 px-2"
                    type="number"
                    placeholder='Part de votre revenu'
                    onKeyUp={handleRetrocession}
                    onChange={handleRetrocession} />
            </td>
            <td>{prixFinal} </td>
        </tr>
    )


}


const ArticleList = ({ eventCycleLang }) => {

    const { idEvent } = useParams()
    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventCycleLangPrestaTypeList, setEventCycleLangPrestaTypeList] = useState(null);

    const getEventCycleLangPrestaType = async () => {

        const params = `WHERE id_event_cycle_lang IN(${eventCycleLang.id_event_cycle_lang})`;

        //const listProductIdToArray = listProductId.split('[').join('').split(']').join('').split(',')

        // const params = `WHERE id_presta_typ IN(${listProductIdToArray.map((id) => id)})`;


        await fetch(`${apiUrlTranslation}?action=getEventCycleLangPrestaType&params=${params}`)
            .then(res => res.json())
            .then(res => {
                setEventCycleLangPrestaTypeList(res)

            })
    }


    useEffect(() => {



        getEventCycleLangPrestaType()
    }
        , [i18n.resolvedLanguage])

    return <div>
        <table className="w-full">
            <thead className="font-bold">
                <tr className="divide-x">
                    <td></td>
                    <td>Nom</td>
                    <td>PRIX PUBLIC BARRE</td>
                    <td>PART MLG</td>
                    <td>PART  DE VOTRE REVENU</td>
                    <td>PRIX AFFICHE FINAL POUR VOTRE ECOSYSTEME</td>
                </tr>
            </thead>

            <tbody className='divide-y '>
                {eventCycleLangPrestaTypeList &&
                    eventCycleLangPrestaTypeList
                        .map(eventCycleLangPrestaType =>
                            <Article
                                getEventCycleLangPrestaType={getEventCycleLangPrestaType}
                                eventCycleLang={eventCycleLangPrestaType.eventCycleLang}
                                presta_type={eventCycleLangPrestaType.presta_type}
                            />)}
            </tbody>
        </table>
    </div>

}

const RegistrationCible = ({ name, href, libelle, visuels, eventCycleLang }) => {

    return <div href_={href}
        className="group flex flex-col justify-between rounded-lg my-5 border-neutral-300 border-x border-y   text-center py-4 h-full w-full  mx-auto  "
    >
        <div className="flex space-x-5 items-center">
            <div className='basis-1/5'>
                <img src={visuels?.medium} alt="" className="w-20 mx-auto" />
            </div>
            <div className="text-left">
                <div className="text-pink-300   font-bold ">{name}</div>
                <div className="text-base font-normal leading-6  mb-3 ">{libelle}</div>
            </div>
        </div>

        <ArticleList
            eventCycleLang={eventCycleLang} />
    </div>


}




const Offre = () => {

    const [eventCycleLangList, setEventCycleLangList] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { idEvent } = useParams();

    const eventContext = useContext(EventContext);

    const { getEventByIdEvent, currentEvent } = eventContext;



    useEffect(() => {

        const getEventCycleLangList = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params= WHERE id_event=${idEvent} AND forum="packs" AND publier!="n"`)
                .then(res => res.json())
                .then(res => {
                    setEventCycleLangList(res)
                })
        }

        getEventCycleLangList()
    }, [i18n.resolvedLanguage])




    return <div className="bg-white mx-5 rounded-2xl py-4 p-5 mb-3">
        {eventCycleLangList && eventCycleLangList


            .map(eventCycleLang => <RegistrationCible

                eventCycleLang={eventCycleLang}
                name={eventCycleLang.event_cycle_lang_nom}
                libelle={eventCycleLang.registration_link_text}
                visuels={eventCycleLang.visuels}
                href={`${currentEvent ? `/events/${currentEvent.id_event}/store/${eventCycleLang.id_event_cycle_lang}` : ''} `}>
                {eventCycleLang.event_cycle_lang_nom}
            </RegistrationCible>)
        }

    </div>

}
const DisplayFormulaireList = ({ formulaireList, event, contactCycleLangList, idContact, title }) => {


    const { t, i18n } = useApiUrlTranslation();

    useEffect(() => {

    }, [i18n.resolvedLanguage])

    return <div className="bg-white mx-5 rounded-2xl py-4 p-5 mb-3">

        <p className=" text-xl py-3 font-bold ">{title}</p>

        <div className="divide-y divide-neutral-400 ">

            {formulaireList && formulaireList.map(formulaire => {

                const idCycleLangPreselectionList = contactCycleLangList ? `&id_cycle_lang_preselection_list=` + contactCycleLangList.map(contactCycleLang => contactCycleLang.cycle_lang.id_cycle_lang).join(",") : null;

                return <><div className="py-1 flex space-x-4  ">
                    <div className=" flex basis-9/12 items-center space-x-4">

                        <img src={formulaire.visuels.large} className="w-8   bg-black" />
                        <div>
                            <p className="text-xs"> {formulaire.contexte_utilisation}</p>
                            <p className="font-bold"> {formulaire.page_title}</p>
                        </div>
                    </div>

                    <div className="flex basis-3/12 space-x-3 items-center">
                        <a
                            target="_blank"
                            href={`/events/${event.id_event}/signup/${formulaire.route}/?form-name=${formulaire.route}&formisvisible=1&id_formulaire=${formulaire.id_formulaire}&lng=en&auteur=${idContact}${idCycleLangPreselectionList}`}
                            className="h-10 cursor-pointer flex ring-2  ring-pink-200 rounded-full text-xs px-4 py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center">
                            <LinkIcon className='w-4 mx-1 ' /> {t("pages.coordinateur_geographique_tools.afficher_le_formulaire")}
                        </a>

                        <div className="hidden cursor-pointer flex bg-pink-200 rounded-full px-4 text-xs  py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center">
                            <PaperAirplaneIcon className='w-4 ' />
                            Send form
                        </div>
                    </div>
                </div>
                </>
            })}
        </div>
    </div>

}




const DisplayContactCycleLang = ({ contactCycleLangList }) => {

    return (
        <div className="flex space-x-4">
            {contactCycleLangList && contactCycleLangList
                //dedup by contactCycleLang.cycle_lang.id_cycle_lang
                .filter((contactCycleLang, index, self) =>
                    index === self.findIndex((t) => (
                        t.cycle_lang.id_cycle_lang === contactCycleLang.cycle_lang.id_cycle_lang
                    ))
                )
                .filter(contactCycleLang => !['collectif_programme'].includes(contactCycleLang.cycle_lang.cycle_lang_type))
                .map(contactCycleLang => <div className="">
                    <div className="hidden">
                        {contactCycleLang.cycle_lang.nom_cycle_lang}
                        {contactCycleLang.cycle_lang.id_cycle_lang}
                        {contactCycleLang.cycle_lang.cycle_lang_type}
                    </div>
                    <img src={contactCycleLang.cycle_lang.logo_better.large} className="w-32 rounded" alt="" />
                </div>)}
        </div>
    )
}

const DisplayContactCycleLangDescriptif = ({ contactCycleLangList }) => {

    return (
        <div className="flex flex-col m-3 mx-5 space-y-5">
            {contactCycleLangList && contactCycleLangList
                //dedup by contactCycleLang.cycle_lang.id_cycle_lang
                .filter((contactCycleLang, index, self) =>
                    index === self.findIndex((t) => (
                        t.cycle_lang.id_cycle_lang === contactCycleLang.cycle_lang.id_cycle_lang

                    ))
                )
                .filter(contactCycleLang => ['collectif_partenaire'].includes(contactCycleLang.cycle_lang.cycle_lang_type))
                .map(contactCycleLang => <div className="">
                    <div className=" dark:text-gray-200">
                        {HTMLReactParser(contactCycleLang.cycle_lang.cycle_lang_descriptif ?? "")}

                    </div>
                </div>)}
        </div>
    )
}

const Packs = () => {


}

const Onboarding = ({ idFormulaireList, event, idContact }) => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [formulaireList, setFormulaireList] = useState(null);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactConferencierList, setContactConferencierList] = useState(false);

    const [contactCycleLangList, setContactCycleLangList] = useState(false);

    const fetchContactCycleLang = async ({ idContact }) => {

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter= c.id_contact=${idContact} AND  contact_cycle_lang.id_cycle_lang !=0 AND  `)

            .then(res => res.json())
            .then(res => {
                setContactCycleLangList(res);
            })
    }

    const getFormulaireList = async () => {

        await fetch(`${apiUrlTranslation}?action=getFormulaireList&params= WHERE id_formulaire IN (${idFormulaireList.join(",")})`)
            .then(response => response.json())
            .then(data => {
                setFormulaireList(data);
            })
    }

    const getContactConferencierList = async ({ idContact, currentEvent }) => {

        await fetch(`${apiUrlTranslation}?action=getContactConferencierList&filter= AND id_event=${currentEvent.id_event}  AND cf.id_contact=${idContact}  AND cf.statut IN('coordinateur_geographique') GROUP BY cf.id_contact`)
            .then(res => res.json())
            .then(res => {
                setContactConferencierList(res[0]);
            })
    }

    useEffect(() => {

        currentEvent && getContactConferencierList({ idContact, currentEvent });
        fetchContactCycleLang({ idContact });
        getFormulaireList();

    }, [i18n.resolvedLanguage, currentEvent, idContact])

    if (!event || !formulaireList) return null;

    return <div>

        <div className="text-white mx-5 py-3 text-2xl font-bold">{t("pages.coordinateur_geographique_tools.phase_1")}</div>

        {contactConferencierList && <div className='text-white mx-5 flex justify-between items-center space-x-5 my-5'>

            <div className="flex items-center space-x-5">
                <img src={contactConferencierList.contact.photos.large} alt="" className="rounded-full h-32 w-32 object-cover" />

                <div className="">
                    <p className="text-xl font-bold"> {contactConferencierList.contact.prenom}  {contactConferencierList.contact.nom}</p>
                    <p className="">{contactConferencierList.contact.fonction}</p>
                    <p className="uppercase">{contactConferencierList.contact.societe}</p>

                    <p className="text-pink-500"> {contactConferencierList.statut.libelle}</p>

                    <p className="mt-3">
                        <a
                            className="  bg-blue-500 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-blue-600   "
                            href={`https://www.myglobalvillage.com/events/1838/signup/coordinateur-geographique-onboarding/c/${contactConferencierList.contact.id_contact}/?form-name=coordinateur-geographique-onboarding&formisvisible=1&id_formulaire=3&lng=fr`}>
                            Modifier mes coordonnées et mon profil de coordinateur</a>
                    </p>
                </div>
            </div>

            <div>
                <DisplayContactCycleLang contactCycleLangList={contactCycleLangList} />
            </div>
        </div>
        }

        {contactConferencierList && <DisplayContactCycleLangDescriptif contactCycleLangList={contactCycleLangList} />}


        <div className="text-white mx-5 py-3 text-2xl font-bold">
            {t("pages.coordinateur_geographique_tools.phase_2")}
        </div>

        <DisplayFormulaireList
            title={t("pages.coordinateur_geographique_tools.lien_contacts_confirmes")}
            formulaireList={formulaireList.filter(formulaire => ["28", "67", "68", "69"].includes(formulaire.id_formulaire))}
            idContact={idContact}
            event={event}
            contactCycleLangList={contactCycleLangList} />


        <DisplayFormulaireList
            title={t("pages.coordinateur_geographique_tools.lien_contacts_non_confirmes")}
            formulaireList={formulaireList.filter(formulaire => ["24"].includes(formulaire.id_formulaire))}
            idContact={idContact}
            event={event}
            contactCycleLangList={contactCycleLangList} />

        {/* <Offre /> */}


        <div className="bg-white mx-5 rounded-2xl py-4 p-5 hidden">

            <p className=" text-xl py-3 font-bold hidden">Send form</p>

            <div className="divide-y divide-neutral-400 ">


                {formulaireList && formulaireList.map(formulaire => {

                    const idCycleLangPreselectionList = contactCycleLangList ? `&id_cycle_lang_preselection_list=` + contactCycleLangList.map(contactCycleLang => contactCycleLang.cycle_lang.id_cycle_lang).join(",") : null;

                    return <>

                        <div className="py-1 flex space-x-4  ">
                            <div className=" flex basis-9/12 items-center space-x-4">

                                <img src={formulaire.visuels.large} className="w-8   bg-black" />
                                <div>
                                    <p className="text-xs "> {formulaire.contexte_utilisation}</p>
                                    <p className="font-bold"> {formulaire.page_title}</p>
                                </div>
                            </div>

                            <div className="flex basis-3/12 space-x-3 items-center">
                                <a
                                    target="_blank"
                                    href={`/events/${event.id_event}/signup/${formulaire.route}/?form-name=${formulaire.route}&formisvisible=1&id_formulaire=${formulaire.id_formulaire}&lng=en&auteur=${idContact}${idCycleLangPreselectionList}`}
                                    className="h-10 cursor-pointer flex ring-2  ring-pink-200 rounded-full text-xs px-4 py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center">
                                    <LinkIcon className='w-4 mx-1 ' /> {t("pages.coordinateur_geographique_tools.afficher_le_formulaire")}
                                </a>

                                <div className="hidden cursor-pointer flex bg-pink-200 rounded-full px-4 text-xs  py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center">
                                    <PaperAirplaneIcon className='w-4 ' />
                                    Send form
                                </div>
                            </div>
                        </div>


                    </>
                })}
            </div>
        </div>
    </div >
}


export default Onboarding;