import React, { useContext, useEffect } from 'react';

import RegistrationFormRow from './RegistrationFormRow'
import RegistrationFormSection from './RegistrationFormSection';
import { useTranslation, Trans } from 'react-i18next';
import RegistrationFormCountry from './RegistrationFormCountry';
import EventContext from '../contexts/EventContext';
import OrganismeVideoUrlChecker from './OrganismeVideoUrlChecker';
import OrganismeWebSiteChecker from './OrganismeWebSiteChecker';

const RegistrationFormContactAndOrganismeSection = ({ contact }) => {

    const { t, i18n } = useTranslation();

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    useEffect(() => {

    }, [i18n.resolvedLanguage, contact])

    return <RegistrationFormSection title={t('form.sections.contact_and_organisation_section.section_title')}>

        <> <RegistrationFormRow
            name='prenom'
            placeholder={t('form.sections.contact_section.prenom')}
            defaultValue={contact?.prenom} />

            <RegistrationFormRow
                name='nom'
                placeholder={t('form.sections.contact_section.nom')}
                defaultValue={contact?.nom} />

            <RegistrationFormRow
                name='fonction'
                placeholder={t('form.sections.contact_section.fonction')}
                defaultValue={contact?.fonction}
            />

            <RegistrationFormRow
                name='port'
                placeholder="Mobile"
                defaultValue={contact?.port}
            />
        </>


        <RegistrationFormRow
            name='mail'
            placeholder="E-mail"
            defaultValue={contact?.mail} />

        <RegistrationFormRow
            name='societe'
            placeholder={t('form.sections.organisme_section.societe')}
            defaultValue={contact?.societe} />
        {/* 
        <RegistrationFormRow
            isRegister
            inputType="textarea"
            name='edito_long'
            placeholder={t('form.sections.organisme_section.edito_long')}
            required={false}
            defaultValue={contact?.edito_long} />

             
        */}
        <RegistrationFormCountry
            contact={contact} required={true} />

        <RegistrationFormRow
            inputType="file"
            name='photo'
            placeholder={t('form.sections.contact_section.photo')}
            defaultValue={contact?.photos.large}
            required={contact?.photos.large != '' ? false : true}
            accept={['.jpeg', '.png', '.jpg']}
            helper="Important  : Cette photo sera restituée lors de l'enregistrement de votre webcast" />

        <RegistrationFormRow
            inputType="file"
            name='logo'
            placeholder={t('form.sections.organisme_section.logo')}
            defaultValue={contact?.logos.large}
            required={contact?.logos.large != '' ? false : true}
            accept={['.jpeg', '.png', '.jpg']} />

        <RegistrationFormRow
            required={false}
            inputType="text"
            name='web'
            placeholder={t('form.sections.organisme_section.web')}
            defaultValue={contact?.web}
            helper={t('form.sections.organisme_section.web_helper')}>
            <OrganismeWebSiteChecker />

        </RegistrationFormRow>



    </RegistrationFormSection>
}

export default RegistrationFormContactAndOrganismeSection;
