import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import RegistrationFormSection from './RegistrationFormSection';
import useApiUrlTranslation from './useApiUrlTranslation';
import HTMLReactParser from 'html-react-parser';

const RegistrationFormStatut = ({ idStatutSelection, formulaire, contact }) => {

    const [statutList, setStatutList] = useState(null);

    const { register } = useFormContext();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactStatutList, setContactStatutList] = useState(null);

    const getContactStatutList = async ({ contact }) => {

        await fetch(`${apiUrlTranslation}?action=getContactStatutList&filter= WHERE id_contact=${contact.id_contact}`)
            .then(res => res.json())
            .then(res => {
                setContactStatutList(res);
            })
    }

    useEffect(() => {

        const fetchStatut = async () => {

            let statutListFormated = [];
            let check = '';

            if (typeof idStatutSelection === "string") {
                check = 'string';
                statutListFormated = idStatutSelection.split(",").map(id => parseInt(id));
            } else {
                statutListFormated = idStatutSelection;
            }

            await fetch(`${apiUrlTranslation}?action=getStatuts&filter= id_statut IN (${statutListFormated && statutListFormated.join(",")}) `)
                .then(res => res.json())
                .then(res => setStatutList(res))
        }

        fetchStatut();
        contact && getContactStatutList({ contact });

    }, [i18n.resolvedLanguage]);

    return <div className="row">

        {/* {JSON.stringify(contactStatutList)} */}

        {statutList && statutList
            .filter(statut => idStatutSelection.includes(parseInt(statut.id_statut)))
            //trier par statut_libelle
            .sort((a, b) => a.statut_libelle.localeCompare(b.statut_libelle))
            // order  by statut_order asc

            .sort((a, b) => a.statut_order - b.statut_order)
            .map(statut =>
                <div
                    key={statut.id_statut}
                    className="">
                    <div className="form-check">
                        <label className="form-check-label" style={{ fontWeight: "normal" }}>
                            <input  {...register("statutList")} type="radio" id="" name="statutList" value={statut.id_statut} data-placeholder="" />  {statut.statut_libelle != "" ? HTMLReactParser(statut.statut_libelle ?? "") : HTMLReactParser(statut.statut_nom ?? "")}
                        </label>
                    </div>
                </div>)}
    </div>

}

export default RegistrationFormStatut;