import { use } from 'i18next';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useApiUrlTranslation from './useApiUrlTranslation';

const PitchRegistrationCycleLangSelectionHeader = ({ formulaire }) => {

    const [cycleLangList, setCycleLangList] = useState([]);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

        const fetchCycleLangList = async () => {

            //extact id_cycle_lang_preselection_list from url REQ http://localhost:3000/events/399/signup/coordinateur-geographique-onboarding/c/607/?form-name=coordinateur-geographique-onboarding&formisvisible=1&id_cycle_lang_preselection_list=2091,2295,2066

            const urlParams = new URLSearchParams(window.location.search);

            const id_cycle_lang_preselection_list = urlParams.get('id_cycle_lang_preselection_list');

            await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=id_cycle_lang IN(${id_cycle_lang_preselection_list}) AND `)
                .then(res => res.json())
                .then(res => setCycleLangList(res))
        }

        fetchCycleLangList()

    }, [i18n.resolvedLanguage]);


    if (!cycleLangList) return null;
    return (


        <div>


            <div className="flex space-x-2 py-2">

                {
                    cycleLangList && cycleLangList
                        //filter cycle_lang_type
                        .filter(cycleLang => ['collectif_planaction', 'collectif_geographique'].includes(cycleLang.cycle_lang_type))
                        .map(cycleLang =>

                            <div key={cycleLang.id_cycle_lang} className="">

                                <img src={cycleLang.attachedFilesCollection.find(file => file.id_attached_file_type == 65).small} className="w-20 rounded" />
                            </div>
                        )
                }
            </div>
        </div>
    )
}

export default PitchRegistrationCycleLangSelectionHeader