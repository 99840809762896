import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../config";
import useApiUrlTranslation from './useApiUrlTranslation';

const EventCard = ({ event }) => {

    const [cycleLangEventLigneList, setCycleLangEventLigneList] = useState([]);

    const [eventEventComposantList, setEventEventComposantList] = useState([]);

    const { t, i18n } = useApiUrlTranslation();

    const visuel = event.logo ? `${event.logos.large}` :
        `${event.event_ligne.el_visuels.large}`


    // const isLieuVille =
    //     event.lieu.lieu_ville !== ""
    //         ? <li><a href={event.web}><i className="fas fa-plane"></i>&emsp; D&eacute;couvrez le village de {event.lieu.lieu_ville}</a></li>
    //         : "";

    const eventLink = event.event_ligne?.id_event_format?.id_event_format === 1 ? `//${event.web}` : `events/${event.id_event}/`;

    // const visuel = <><p><b>Le village francophone vous y am&egrave;ne :</b></p>
    //     <ul className="event-list__list__decoration-none">
    //         {isLieuVille}
    //         <li><i className="fas fa-camera"></i><a href={`${event.web}/${event.mega_demonstrateur_id}/connected_studios/`}>&emsp;Liste des studios connect&eacute;s</a></li>
    //         <li><i className="fas fa-file-signature"></i><a href={`${event.web}/${event.mega_demonstrateur_id}/pitch_registration/`}>&emsp;Inscriptions gratuites ouvertes</a></li>
    //     </ul></>

    useEffect(() => {

        const getCycleLangEventLigne = async () => {

            await fetch(`${API_URL}?action=getCycleLangEventLigne&params= WHERE id_event_ligne=${event.event_ligne.id_event_ligne} `)
                .then(res => res.json())
                .then(res => setCycleLangEventLigneList(res))
        }

        const getEventEventComposant = async () => {

            await fetch(`${API_URL}?action=getEventEventComposant&req_suite= WHERE id_event=${event.id_event} `)
                .then(res => res.json())
                .then(res => setEventEventComposantList(res))
        }

        getCycleLangEventLigne();
        getEventEventComposant();

    }, [])

    return <div className="card mb-3 bg-neutral-900 rounded-2xl">
        <Link target="_blank" rel="noreferrer" to={eventLink} className="group mx-auto flex items-center px-3 py-2 border-x-1 border-2 border-neutral-400 hover:border-pink-500 border-transparent text-base font-medium rounded-2xl shadow-sm text-white justify-center" style={{ textTransform: "capitalize" }}>
            <div className="row no-gutters p-3">
                <div className="col-md-2">
                    <a href={eventLink} rel="noreferrer" target="_blank"><img src={visuel} className="card-img  " alt="" /></a>
                </div>
                <div className="col-md-4">
                    <div className="card-body">

                        <div>

                            <span class="mb-3 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-neutral-800 text-gray-400">
                                {event.event_ligne.id_event_format ? event.event_ligne?.id_event_format?.event_format_nom : ''}
                            </span>

                        </div>
                        <h4 className="text-xl pb-3 font-black uppercase"> {event.nom}</h4>
                        <h5 className="pb-2 text-gray-300"><i className="far fa-calendar-check"></i> {event.precision_date}</h5>
                        <h5 className="text-gray-300"><i className="fas fa-map-marker-alt"></i> {event.lieu.lieu_nom} - {event.lieu.lieu_ville}</h5>

                        {event.event_ligne.id_event_format.id_event_format === 6 && cycleLangEventLigneList.length > 0 ? <Link className="btn btn-secondary" to={`/planaction/${cycleLangEventLigneList[0].id_cycle_lang.id_cycle_lang}/global-chalenge`}>Pré-qualif</Link> : ''}
                    </div>
                </div>
                <div className="col-md-4 p-3 rounded">
                    {/*visuel*/}
                    {eventEventComposantList.map(eventEventComposant =>
                        <div className="flex p-1 space-x-2 items-center">
                            {eventEventComposant.visuel != "//www.mlg-consulting.com/manager_cc/docs/archives/" ? <img src={eventEventComposant.visuel} alt="" style={{ width: '30px' }} /> : <div className="rounded-full bg-teal-900 text-white w-8 h-8 flex items-center justify-center">P</div>}

                            <div>
                                {eventEventComposant.event_composant_nom}
                            </div>
                        </div>)}
                </div>
                <div className="col-md-2 p-3 rounded align-middle flex items-center">
                    {event.event_ligne.id_event_format ? !['4', '6'].includes(event.event_ligne.id_event_format.id_event_format) && <div className="border-neutral-700 text-gray-400  rounded-full border-2 px-3 py-2 group-hover:border-pink-600">{t("global.en_savoir_plus")}  </div> : ""}
                </div>
            </div>
        </Link>
    </div >
}

export default EventCard;