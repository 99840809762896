import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { isDev } from '../utils'
import NavTranslation from './NavTranslation'
import "./Nav.css"
import darkModeContext from '../contexts/DarkModeContext'

const Nav = () => {

    const { t, i18n } = useTranslation();

    const { darkMode } = useContext(darkModeContext);

    return (
        <>
            {isDev && <NavTranslation />}
            <nav className="navbar navbar-expand-md navbar-dark dark:text-white dark:bg-neutral-950 border-y  dark:border-neutral-900 ">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src="https://www.mlg-consulting.com/manager_cc/docs/archives/230906125631_output-onlinepngtools.png"
                            alt=""
                            className={`${darkMode && 'invert-custom'}  w-52`} /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle dark:text-white text-neutral-700 hover:text-neutral-700" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" to="#">{t('event_nav.a_propos')}</Link>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li style={{ display: 'none' }}><Link className="dropdown-item" to="/whatis">{t('nav.whatis')}</Link></li>

                                    <li style={{ display: 'none' }}><Link className="dropdown-item" to="/connected-studios">{t('nav.studios')}</Link></li>
                                    <li style={{ display: 'none' }}><Link className="dropdown-item" to="/commite-organisation">{t('nav.commite_orga')}</Link></li>
                                    <li style={{ display: 'none' }}><Link className="dropdown-item" to="/map">{t('nav.map')}</Link></li>
                                    <li><Link className="dropdown-item" to="/whois">{t('nav.whois')}</Link></li>
                                    <li className='hidden'><Link className="dropdown-item" to="/leadership-team">Notre Leadership Team</Link></li>
                                </ul>
                            </li>





                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle dark:text-white text-neutral-700" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" to="#">{t('nav.agenda')}</Link>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li><Link className="dropdown-item" to="/leading-events">Evénéments leaders</Link></li>
                                    <li><Link className="dropdown-item" to="/calendar">
                                        {/* {t('nav.next_events')} */}
                                        Agenda annuel
                                    </Link></li>
                                    <li><Link className="dropdown-item hidden" to="/pre-selection-calendar">Pré-qualifications sessions de pitch</Link></li>

                                    <li style={{ display: 'none' }}><Link className="dropdown-item" to="/affiliated-events">Evénements affiliés</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle dark:text-white text-neutral-700" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" to="#">{t('nav.annuaire_collectifs')} </Link>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li className="hidden"><Link className="dropdown-item" to="/collectif-categorie/collectif_sectoriel">{t('nav.collectifs_sectoriels')} </Link></li>
                                    <li className=""><Link className="dropdown-item" to="/collectif-categorie/collectif_geographique">
                                        {t('nav.collectifs_geographiques')}
                                    </Link></li>
                                    <li className="hidden"><Link className="dropdown-item" to="/collectif-categorie/collectif_technologique">{t('nav.collectifs_technologiques')} </Link></li>
                                    <li><Link className="dropdown-item" to="/collectif-categorie/collectif_planaction">{t('nav.collectifs_planaction')}</Link></li>
                                    <li><Link className="dropdown-item" to="/collectif-categorie/collectif_partenaire">Cercle des partenaires</Link></li>
                                    <li style={{ display: 'none' }}><Link className="dropdown-item" to="#">{t('nav.tetes_affiche')}</Link></li>
                                    <li style={{ display: 'none' }}><Link className="dropdown-item" to="#">Annuaire des coordinateurs</Link></li>
                                    <li><Link className="dropdown-item" to="/programmes-innovation">{t('nav.programmes')}</Link></li>
                                    <li style={{ display: 'none' }}><Link className="dropdown-item" to="#">Annuaire des lauréats</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown" style={{ display: 'none' }}>
                                <Link className="nav-link dropdown-toggle dark:text-white text-neutral-700" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" to="#">PRESSE</Link>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li><Link className="dropdown-item" to="/press">{t('event_nav.espace_presse')}</Link></li>
                                    <li><Link className="dropdown-item" to="/press-partenaire">{t('event_nav.partenaires_presse')}</Link></li>
                                </ul>
                            </li>

                            <Link style={{ textTransform: 'capitalize', }} className="hidden nav-link text-white  font-bold " to={`/programm/webcasts`}> Webcasts </Link>




                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Nav
