import React, { useContext, useEffect, useState } from 'react'
import {
    useParams

} from "react-router-dom";
import { Link } from 'react-router-dom'
import EventContext from '../contexts/EventContext'
import styled from 'styled-components';
import './NavEvent.css';
import NavTranslation from './NavTranslation';
import { arrayIntersection, isDev } from '../utils';
import useApiUrlTranslation from './useApiUrlTranslation';
import darkModeContext from '../contexts/DarkModeContext';
import { HomeIcon } from '@heroicons/react/24/solid';
import { HomeModernIcon } from '@heroicons/react/24/outline';
import NavBeta from './NavBeta';

const NavEvent = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const { darkMode, toggleDarkMode } = useContext(darkModeContext);

    const { idEvent, id_cycle_lang } = useParams();

    const eventContext = useContext(EventContext);

    const [eventEventComposantList, setEventEventComposantList] = useState([]);

    const { getEventByIdEvent, currentEvent } = eventContext;

    const [eventCycleLangList, setEventCycleLangList] = useState(false)

    const MyglobalvillageLink = styled(Link)`
 
&:hover {
    text-decoration: underline;
  }
`

    useEffect(() => {


        const getEventCycleLangList = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params= WHERE id_event=${idEvent} AND forum="packs" AND publier!="n"`)
                .then(res => res.json())
                .then(res => {
                    setEventCycleLangList(res)
                })
        }

        const getEventEventComposant = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventEventComposant&req_suite= WHERE id_event=${idEvent}`)
                .then(res => res.json())
                .then(res => setEventEventComposantList(res))
        }

        getEventEventComposant();
        getEventByIdEvent(idEvent)
        getEventCycleLangList()
    }, [i18n.resolvedLanguage])

    if (
        (["2270", "3013", "3014"].includes(id_cycle_lang)
            || (currentEvent && [1731, '1731', "1747", "1736"].includes(currentEvent.id_event))
        ) && window.location.pathname.includes("/signup")) {

        return <NavTranslation />
    }

    return (currentEvent && <>
        <div className='dark:bg-neutral-950 d-flex justify-content-end pe-3 '>
            <MyglobalvillageLink
                to="/"
                style={{ fontSize: '12px' }}
                className="nav-link small dark:text-white align-self-center mx-3">
                <div className="flex space-x-2 items-center"> <img src="https://www.mlg-consulting.com/manager_cc/docs/archives/230906125631_output-onlinepngtools.png"
                    alt=""
                    className={`${darkMode && 'invert-custom'} h-8 ${currentEvent.event_categorie == "cdt" ? "hidden" : ''}`} />
                </div>



            </MyglobalvillageLink>
            {isDev && <NavTranslation />}
        </div>

        <nav className={`lg:pr-10  lg:mr-20 navbar navbar-expand-md ${darkMode && 'navbar-dark'} dark:text-white  border-y border-neutral-100 dark:bg-neutral-950 dark:border-neutral-900`}>
            <div className="container-fluid">
                <div className="flex items-center "><Link className="navbar-brand" to={`/events/${currentEvent.id_event}`}><img src={`${currentEvent && currentEvent.logo ? currentEvent.logos.small : currentEvent.event_ligne.el_visuels.small}`} alt="" style={{ height: "70px" }} /></Link>
                    <div className='dark:text-white lg:text-sm text-xs'>
                        {currentEvent.nom}<br />
                        <i className="far fa-calendar-minus"></i> {currentEvent.precision_date}<br />
                        <i className="fas fa-map-marker-alt"></i> {currentEvent.lieu.lieu_nom} -  {currentEvent.lieu.lieu_ville} <br /> {currentEvent.lieu.lieu_pays}
                    </div>
                </div>
                <button className="navbar-toggler hidden lg:visible" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse hidden lg:visible" id="navbarCollapse">
                    <ul className="navbar-nav ms-auto mb-2 mb-md-0">

                        <Link style={{ textTransform: 'capitalize' }} className="nav-link font-bold text-white " to={currentEvent ? `/events/${currentEvent.id_event}/` : ''}>Accueil </Link>

                        <li className={`nav-item dropdown ${idEvent == 1820 ? 'hidden' : ''}`}>
                            <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">{t('event_nav.a_propos')}</a>
                            <ul className="dropdown-menu" aria-labelledby="dropdown04">

                                <li><a className={`dropdown-item ${currentEvent.id_event == 1822 ? 'hidden' : ''}`} href={currentEvent ? `/events/${currentEvent.id_event}/highligths/temps_forts` : ''}> {t('event_nav.temps_forts')}</a></li>
                                <li><a className={`dropdown-item ${currentEvent.id_event == 1822 ? 'hidden' : ''}`} href={currentEvent ? `/events/${currentEvent.id_event}/highligths/event-target` : ''}> Les cibles de l'événement</a></li>
                                <li><Link className={`dropdown-item ${currentEvent.id_event == 1822 ? 'hidden' : ''}`} to={currentEvent ? `/events/${currentEvent.id_event}/programme-thematiques` : ''}> Thématique du programme</Link></li>
                                {idEvent == 1822 && <>
                                    <li><a className={`dropdown-item`} href={currentEvent ? `/events/1822/page/5860` : ''}>{t('event_nav.evenement_qq_mots')}</a></li>
                                    <li><a className="dropdown-item" href={currentEvent ? `/events/1822/page/5859` : ''}> {t('event_nav.info_pratiques')}</a></li>
                                </>}

                                <li><Link className={`dropdown-item ${['1838', '1822'].includes(currentEvent.id_event) ? 'hidden' : ''}`} to={currentEvent ? `/events/${currentEvent.id_event}/event-description` : ''}>{t('event_nav.evenement_qq_mots')}</Link></li>
                                <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/commite-organisation-event` : ''}>{t('event_nav.comite_orga')}</Link></li>

                                <li><Link className={`dropdown-item ${currentEvent.event_categorie == "cdt" || ![1809].includes(idEvent) ? " " : ''}`} to={currentEvent ? `/events/${currentEvent.id_event}/partenaires` : ''}>{t('event_nav.partenaires')}</Link></li>

                                <li><Link className={`dropdown-item ${currentEvent.id_event == 1822 ? 'hidden' : ''}`} to={currentEvent ? `/events/${currentEvent.id_event}/infos-pratiques` : ''}> {t('event_nav.info_pratiques')}</Link></li>
                                <li className=''><a className="dropdown-item" href={`/events/${currentEvent.id_event}/coordinateurs-collectif`}>Coordinateurs</a></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">{t('event_nav.programme')}</a>
                            <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm` : ''}>{t('event_nav.programme_complet')}</Link></li>
                                {/*  <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/temps-forts` : ''}>{t('event_nav.temps_forts')}</Link></li>
                                <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm-places` : ''}>{t('event_nav.programme_par_lieux')}</Link></li>
                                <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/speakers` : ''}>Speakers</Link></li>
                          */}

                                {idEvent == 1820 && <>
                                    <li><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/annuaire-techxplorateurs/` : ''}>{t('event_nav.techxplorations_annuaire')}</a></li>
                                    <li className=''><a className="dropdown-item" href={`/events/${currentEvent.id_event}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${currentEvent.id_event}/signup/techxplorateur/c/{id_contact}?formisvisible=1&id_formulaire=31&form-name=techxplorateur&lng=fr`}>Demande de participation</a></li>
                                </>
                                }
                                {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["2", "32", "14"]).length > 0 &&
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm/pitchs` : ''}> {t('event_nav.pitching_session')}</Link></li>
                                }

                                {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["39"]).length > 0 &&
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm/techreviews` : ''}>Programme des Techreviews</Link></li>
                                }

                                {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["15"]).length > 0 &&
                                    <li className=""><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm/networking` : ''}>Les soirées</Link></li>
                                }

                                {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["38"]).length > 0 &&
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm/texplorations` : ''}>{t('event_nav.techxplorations_programme')}</Link></li>
                                }

                            </ul>
                        </li>

                        {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["3"]).length > 0 &&
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">{t('event_nav.demonstrateurs')}</a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/event-component/3` : ''}>{t('event_nav.presentation')}</a></li>
                                </ul>
                            </li>}


                        {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["6"]).length > 0 &&
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">{t('event_nav.parcours_de_visite')}</a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/event-component/6/` : ''}>{t('event_nav.presentation')}</a></li>
                                    {/* 
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm` : ''}>Proposition valeur</Link></li>
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm` : ''}>How to</Link></li>
                                    */}
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm/parcours` : ''}>{t('event_nav.programme_parcours_de_visite')}</Link></li>
                                    {currentEvent.event_ligne.categorie != "cdt" && <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/signup/startup` : ''}>{t('event_nav.positionner_parcours_de_visite')}</Link></li>}
                                    <li><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/candidats2/` : ''}>Exposants</a></li>
                                </ul>
                            </li>}

                        {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["2", "32", "14"]).length > 0 &&
                            <li className="hidden nav-item dropdown">
                                <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">{t('event_nav.pitching_session')} </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/event-component/14/` : ''}>{t('event_nav.presentation')}</a></li>
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm/pitchs` : ''}> {t('event_nav.pitching_session')}</Link></li>
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/become-candidate` : ''}>Je souhaite participer</Link></li>
                                    {/*<li><Link rel="noreferrer" to={`/events/${currentEvent.id_event}/signup/jury`} className="dropdown-item">{t('event_nav.devenir_jury')}</Link></li>
                                    <li><Link rel="noreferrer" to={`/events/${currentEvent.id_event}/signup/startup`} className="dropdown-item">{t('event_nav.devenir_candidat')}</Link></li>
                                */}

                                    {/* <li><Link rel="noreferrer" to={`/events/${currentEvent.id_event}/ecosystem-contact-list/14`} className="dropdown-item">{t('event_nav.devenir_jury')}</Link></li>
                                    <li><Link rel="noreferrer" to={`/events/${currentEvent.id_event}/ecosystem-contact-list/14`} className="dropdown-item">{t('event_nav.devenir_candidat')}</Link></li>
                                    <li><Link rel="noreferrer" to={`/events/${currentEvent.id_event}/contributions/`} className="dropdown-item">Contributions</Link></li> */}


                                    <li><Link rel="noreferrer" to={`/events/${currentEvent.id_event}/candidats`} className="dropdown-item">{t('event_nav.annuaire_candidats')}</Link></li>
                                    <li><Link rel="noreferrer" target="_blank" to={`/events/${currentEvent.id_event}/annuaire-jury/`} className="dropdown-item"> {t('event_nav.membres_du_jury')}</Link></li>
                                    <li className='d-none'><a className="dropdown-item" href={`/events/${currentEvent.id_event}/ecosystem-contact-list/14`}>Coordinateurs</a></li>
                                </ul>
                            </li>
                        }


                        {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["39"]).length > 0 &&
                            <li className="hidden nav-item dropdown">
                                <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">Tech'reviews </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li className='d-none'><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/event-component/39/` : ''}>{t('event_nav.presentation')}</a></li>
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm/techreviews` : ''}>Programme des Techreviews</Link></li>
                                </ul>
                            </li>
                        }


                        {
                            arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["34"]).length > 0 &&

                            <li className="hidden nav-item dropdown">
                                <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">{t('event_nav.delegations')}  </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">

                                    {currentEvent.id_event != 1727 && <li><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/event-component/34/` : ''}>{t('event_nav.presentation')}</a></li>}
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/ecl` : ''}>Annuaire des délégations</Link> </li>
                                    <li><a className="dropdown-item hidden" href={`/events/${currentEvent.id_event}/ecosystem-contact-list/34`}>Coordinateurs</a></li>
                                </ul>
                            </li>
                        }

                        {currentEvent.id_event != 1770 &&
                            <li className="nav-item dropdown hidden">
                                <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false"> {t('global.collectifs')} </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/ecl` : ''}> {t('nav.annuaire_collectifs')}</Link> </li>
                                </ul>
                            </li>
                        }

                        {currentEvent.id_event != 1770 &&
                            <li className="nav-item dropdown d-none">
                                <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false"> Pays </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/ecl-geo` : ''}>Annuaire</Link> </li>
                                </ul>
                            </li>
                        }

                        {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["15"]).length > 0 &&
                            <li className="hidden nav-item dropdown">
                                <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">{t('event_nav.soiree')} </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">

                                    <li className='hidden'><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/event-component/15/` : ''}>{t('event_nav.presentation')}</a></li>

                                    <li className=''><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/soirees/` : ''}>{t('event_nav.presentation')}</a></li>

                                    <li className=""><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm/networking` : ''}>Les soirées</Link></li>
                                    <li className="d-none"><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm/texplorations` : ''}>{t('event_nav.techxplorations_annuaire')}</Link></li>
                                </ul>
                            </li>
                        }

                        {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["38"]).length > 0 &&
                            <li className="hidden nav-item dropdown">
                                <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false"> {t('pages.techxploration.techxploration')}</a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                    <li><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/event-component/38/` : ''}>{t('event_nav.presentation')}</a></li>
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/programm/texplorations` : ''}>{t('event_nav.techxplorations_programme')}</Link></li>
                                    <li><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/annuaire-techxplorateurs/` : ''}>{t('event_nav.techxplorations_annuaire')}</a></li>
                                    <li className='hidden'><a className="dropdown-item" href={`/events/${currentEvent.id_event}/ecosystem-contact-list/38`}>Coordinateurs</a></li>
                                    <li className=''><a className="dropdown-item" href={`/events/${currentEvent.id_event}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${currentEvent.id_event}/signup/techxplorateur/c/{id_contact}?formisvisible=1&id_formulaire=31&form-name=techxplorateur&lng=fr`}>Demande de participation</a></li>
                                </ul>
                            </li>
                        }

                        <li className="nav-item dropdown" style={{ display: "none" }}>
                            <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">{t('event_nav.parcours_de_visite')}</a>
                            <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                <li style={{ display: 'none' }}><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/jury-registration` : ''}>Je souhaite être membre du jury</Link></li>
                                <li style={{ display: 'none' }}><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/pitch-registration` : ''}>Je souhaite pitcher ma solution</Link></li>
                                <li><a rel="noreferrer" target="_blank" href={`https://${currentEvent.web}/${currentEvent.mega_demonstrateur.id_event_cycle_lang}/inscription_jures/`} className="dropdown-item">Je souhaite être membre du jury</a></li>
                                <li><a rel="noreferrer" target="_blank" href={`https://${currentEvent.web}/${currentEvent.mega_demonstrateur.id_event_cycle_lang}/pitch_registration/`} className="dropdown-item">Je souhaite pitcher ma solution</a></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle dark:text-white" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" to="#" style={{ display: 'none' }}>PRESSE</Link>
                            <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/press-event` : ''}>Espace presse</Link></li>
                                <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/press-partenaire-event` : ''}>Partenaires presse</Link></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle dark:text-white" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" to="#" style={{ display: 'none' }}>DELEGATIONS</Link>
                            <ul className="dropdown-menu" aria-labelledby="dropdown04">
                                <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/delegation/delegations-affiliees` : ''}>Les Délégations Affiliées</Link></li>
                                <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/delegation/delegations-sectorielles` : ''}>Les Délégations Sectorielles</Link></li>
                            </ul>
                        </li>

                        <li className="hidden nav-item dropdown">
                            <Link className="nav-link dropdown-toggle dark:text-white" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" to="#"  >Annuaire</Link>
                            <ul className="dropdown-menu" aria-labelledby="dropdown04">

                                {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["2", "32", "14"]).length > 0 &&
                                    <>
                                        <li><Link rel="noreferrer" to={`/events/${currentEvent.id_event}/candidats`} className="dropdown-item">{t('event_nav.annuaire_candidats')}</Link></li>
                                        <li><Link rel="noreferrer" target="_blank" to={`/events/${currentEvent.id_event}/annuaire-jury/`} className="dropdown-item"> {t('event_nav.membres_du_jury')}</Link></li>
                                    </>
                                }

                                {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["34"]).length > 0 &&
                                    <li><Link className="dropdown-item" to={currentEvent ? `/events/${currentEvent.id_event}/ecl` : ''}>Annuaire des délégations</Link> </li>
                                }

                                {arrayIntersection(eventEventComposantList.map(item => item.id_event_composant), ["38"]).length > 0 &&
                                    <li><a className="dropdown-item" href={currentEvent ? `/events/${currentEvent.id_event}/annuaire-techxplorateurs/` : ''}>{t('event_nav.techxplorations_annuaire')}</a></li>
                                }

                            </ul>
                        </li>

                        <Link className="nav-link d-none" to={currentEvent ? `/events/${currentEvent.id_event}/infos-pratiques` : ''}> INFOS PRATIQUES   { /*t('event_nav.info_pratiques')*/}</Link>
                        {/*currentEvent.event_etat != "bilan" && <Link className='nav-link btn btn-primary text-white shadow-sm ' to={`/events/${currentEvent.id_event}/signup`}>{t('event_nav.inscription')}</Link>*/}

                        {currentEvent.id_event == 1789 && <Link style={{ textTransform: 'capitalize', }} className="nav-link text-white  " to={currentEvent ? `/events/${currentEvent.id_event}/pays-recap` : ''}> {t('event_nav.pays_affilies')}  </Link>}

                        <Link style={{ textTransform: 'capitalize', }} className="nav-link text-white hidden  " to={currentEvent ? `/events/${currentEvent.id_event}/programm/blitz-pitch` : ''}> Blitz pitch </Link>

                        {/* {
                            ['bilan'].includes(currentEvent.event_etat) && <Link className="nav-link rounded flex items-center  " to={currentEvent ? `/events/${currentEvent.id_event}/palmares` : ''}><i className="fas fa-trophy mr-1"></i>  {t('global.palmares')}</Link>
                        }

                        {['bilan'].includes(currentEvent.event_etat) && <Link className="nav-link bg-danger flex items-center rounded dark:text-white" to={currentEvent ? `/events/${currentEvent.id_event}/replay` : ''}><i className="fas fa-video mr-1"></i> REPLAY</Link>} */}
                        {/* 
                        <li className={`nav-item dropdown ${['bilan'].includes(currentEvent.event_etat) ? 'hidden' : ''}`}>

                            <Link className="nav-link dropdown-toggle bg-pink-700 text-white rounded-full px-4" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" to="#"  >{t('event_nav.inscription')}</Link>
                            <ul className="dropdown-menu" aria-labelledby="dropdown04">

                                {eventCycleLangList && eventCycleLangList.map(eventCycleLang => <li><Link rel="noreferrer" target="_blank" to={currentEvent ? `/events/${currentEvent.id_event}/store/${eventCycleLang.id_event_cycle_lang}` : ''} className="dropdown-item">{eventCycleLang.event_cycle_lang_nom}</Link></li>)}

                                <li><Link rel="noreferrer" target="_blank" to={currentEvent ? `/events/${currentEvent.id_event}/become-candidate/` : ''} className="dropdown-item">Sessions de pitch</Link></li>

                            </ul>
                        </li> */}

                        {currentEvent.event_etat != "bilan" && <Link className='nav-link text-white rounded-full bg-pink-600 px-4 font-bold  ' to={`/events/${currentEvent.id_event}/registration`} style={{ textTransform: 'capitalize' }}>{t('event_nav.inscription')}</Link>}

                        {['bilan'].includes(currentEvent.event_etat) && <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle dark:text-white" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">Bilan</a>
                            <ul className="dropdown-menu" aria-labelledby="dropdown04">


                                <li className=''> <Link target="_blank" className="dropdown-item rounded flex items-center  " to={currentEvent ? `/events/${currentEvent.id_event}/highligths/bilan` : ''}>  Bilan de l'édition {currentEvent.event_an}</Link></li>
                                <li className=''> <Link target="_blank" className="dropdown-item rounded flex items-center  " to={currentEvent ? `/events/${currentEvent.id_event}/palmares` : ''}><i className="fas fa-trophy mr-1"></i>  {t('global.palmares')}</Link></li>

                                <li className=""><Link className="dropdown-item  flex items-center rounded" to={currentEvent ? `/events/${currentEvent.id_event}/replay` : ''}><i className="fas fa-video mr-1 text-red-700"></i> Replay</Link></li>

                            </ul>
                        </li>}
                    </ul>
                </div>
            </div >
        </nav >

        <NavBeta />
    </>
    )
}

export default NavEvent;