import React, { useState, useEffect, useContext, useRef } from 'react';
import Layout from './Layout'
import Onboarding from './Onboarding'
import EventContext from '../contexts/EventContext';
import { useParams } from 'react-router-dom';
import { use } from 'i18next';

const CoordinateurGeographiqueTools = () => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const { idContact } = useParams();

    useEffect(() => {

    }, [currentEvent, idContact])

    return (
        <Layout>
            <Onboarding
                idFormulaireList={[24, 28, 67, 68, 69]}
                event={currentEvent}
                idContact={idContact}
            />
        </Layout>
    )
}

export default CoordinateurGeographiqueTools;