import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import useApiUrlTranslation from "./useApiUrlTranslation";
import HTMLReactParser from 'html-react-parser';


const Engagement = ({ engagement }) => {

    return ""
}

const DisplayEngagements = ({ eventContactTypeEngagementList, role }) => {

    return <div>
        <ul>
            {eventContactTypeEngagementList && eventContactTypeEngagementList

                .map((eventContactTypeEngagementList, index) =>
                    <li key={index} className="list-disc">
                        {HTMLReactParser(eventContactTypeEngagementList.name)}
                    </li>)}
        </ul>
    </div>
}

const DisplayContgreparties = ({ eventContactTypeEngagementContrepartieList }) => {

    const { t, i18n } = useApiUrlTranslation();

    useEffect(() => {

    }
        , [i18n.resolvedLanguage])

    return <div>

        {eventContactTypeEngagementContrepartieList
            && eventContactTypeEngagementContrepartieList.map((eventContactTypeEngagementContrepartie, index) =>
                <li key={index} className="list-disc">
                    {eventContactTypeEngagementContrepartie.name}
                </li>)
        }
    </div>
}

const Role = ({ role, eventContactTypeEngagementContrepartieList, eventContactTypeEngagementList }) => {

    const { t } = useApiUrlTranslation();


    return <tr className="divide-x divide-gray-200 text-sm">
        <td className="p-4 bg-pink-700 text-white font-bold" >    {role.libelle}  </td>
        <td className="p-4 align-text-top">
            <DisplayEngagements
                eventContactTypeEngagementList={eventContactTypeEngagementList}
                role={role} />
        </td>
        <td className="p-4 align-text-top ">

            <DisplayContgreparties
                eventContactTypeEngagementContrepartieList={eventContactTypeEngagementContrepartieList && eventContactTypeEngagementContrepartieList
                    .filter(item => item.id_event_contact_type == role.id_event_contact_type)
                } />
        </td>
        <td className=' p-x-3'>        <label className="text-pink-700 font-normal pb-3"><input type="checkbox" name="" id="" /> {t('form.sections.contreparties_section.je_confirme')} {role.libelle}</label></td>
    </tr>
}

const RegistrationFormEngagementsContrepartiesSection = ({ title, instructions, formulaire }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventContactTypeEngagementContrepartieList, setEventContactTypeEngagementContrepartieList] = useState(false);

    const [eventContactTypeEngagementList, setEventContactTypeEngagementList] = useState(false);

    const [eventContactTypeList, setEventContactTypeList] = useState(false);

    const [eventContactTypeSelectedList, setEventContactTypeSelectedList] = useState(false);

    const [sortOrder, setSortOrder] = useState(['124', '267', '202', '266']);

    const fetchEventContactTypeEngagement = async (req) => {

        await fetch(`${apiUrlTranslation}?action=fetchEventContactTypeEngagement&req=${req}`)
            .then(res => res.json())
            .then(res => {
                setEventContactTypeEngagementList(res);
            })
    }

    const getEventContactTypeList = async (eventContactTypeSelectedList) => {

        await fetch(`${apiUrlTranslation}?action=getEventContactTypeList&filter=WHERE id_event_contact_type IN (${eventContactTypeSelectedList.join(',')})  `)
            .then(res => res.json())
            .then(res => {
                setEventContactTypeList(res);

                fetchEventContactTypeEngagement(`WHERE id_event_contact_type IN (${res.map(item => item.id_event_contact_type).join(',')})`)
            })
    }

    const fetchEventContactTypeEngagementContrepartie = async (eventContactTypeSelectedList) => {

        await fetch(`${apiUrlTranslation}?action=fetchEventContactTypeEngagementContrepartie&filter=WHERE id_event_contact_type IN (${eventContactTypeSelectedList.join(',')})`)
            .then(res => res.json())
            .then(res => {
                setEventContactTypeEngagementContrepartieList(res);
            })
    }

    useEffect(() => {
        let eventContactTypeSelectedList = [];

        switch (formulaire.id_formulaire) {

            case "3":
                eventContactTypeSelectedList = [formulaire.id_event_contact_type_main];
                //eventContactTypeSelectedList = ["202", "266", "124", "267"];
                break;

            case "9": eventContactTypeSelectedList = [237];
                break;

            case "6": eventContactTypeSelectedList = [239];
                break;

            default: eventContactTypeSelectedList = [];
                break;
        }

        getEventContactTypeList(eventContactTypeSelectedList);
        fetchEventContactTypeEngagementContrepartie(eventContactTypeSelectedList);

        setEventContactTypeSelectedList(eventContactTypeSelectedList)


    }, [formulaire, i18n.resolvedLanguage]);


    return (
        <RegistrationFormSection
            title={title}
            instructions={instructions}>

            <table className="">

                <thead>
                    <tr>
                        <th className="px-3" _="Votre rôle">{t('form.sections.contreparties_section.votre_role')}</th>
                        <th className="px-3" _="Vos engagements">{t('form.sections.contreparties_section.engagements')}</th>
                        <th className="px-3" _="Contrepartie / bénéfices">{t('form.sections.contreparties_section.contreparties')}</th>
                    </tr>
                </thead>


                <tbody className="divide-y">
                    {eventContactTypeList && eventContactTypeList
                        //trier par role.id_event_contact_type  [124, 267, 202, 266]

                        .sort((a, b) => {
                            // Get the index of the id_event_contact_type in the sortOrder array
                            let indexA = sortOrder.indexOf(a.id_event_contact_type);
                            let indexB = sortOrder.indexOf(b.id_event_contact_type);

                            // Handle cases where id_event_contact_type might not be in the sortOrder array
                            if (indexA === -1) indexA = Number.MAX_VALUE;
                            if (indexB === -1) indexB = Number.MAX_VALUE;

                            return indexA - indexB; // Sort based on the index in the sortOrder array
                        })
                        .map((role, index) =>
                            <Role
                                role={role}
                                key={index}
                                eventContactTypeEngagementList={eventContactTypeEngagementList && eventContactTypeEngagementList.filter(item => item.id_event_contact_type == role.id_event_contact_type)}
                                eventContactTypeEngagementContrepartieList={eventContactTypeEngagementContrepartieList && eventContactTypeEngagementContrepartieList.filter(item => item.id_event_contact_type == role.id_event_contact_type)}
                            />
                        )}

                    {/* {roleList && roleList.map((role, index) =>
                        <Role
                            role={role}
                            key={index}
                            eventContactTypeEngagementContrepartieList={eventContactTypeEngagementContrepartieList.filter(item => item.id_event_contact_type == role.id_event_contact_type)} />
                    )} */}



                </tbody>
                {/* // {eventContactTypeEngagementContrepartieList
                //     && eventContactTypeEngagementContrepartieList.map((eventContactTypeEngagementContrepartie, index) =>

                //         <tr key={index}>
                //             <td>
                //                 <input type="checkbox" name="engagement_contrepartie" value={eventContactTypeEngagementContrepartie.id} />
                //                 <label htmlFor="engagement_contrepartie">{eventContactTypeEngagementContrepartie.name}</label>
                //             </td>
                //         </tr>

                //     )} */}
            </table>
        </RegistrationFormSection>
    )
}

export default RegistrationFormEngagementsContrepartiesSection