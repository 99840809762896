import React, { useState, useEffect } from 'react'
import PageSection from './PageSection'
import { API_URL } from '../config';
import { useTranslation } from 'react-i18next';
import useApiUrlTranslation from './useApiUrlTranslation';
import { Splide, SplideSlide } from '@splidejs/react-splide';
//import './Carousel.css'


const itemList = ['220217103852_header-portail-plan-de-travail-1-02.png',
    "220217103846_header-portail-03.png",
    '220217103841_header-portail-04.png',
    '220217103829_header-portail-plan-de-travail-1-01.png']



const CarouselItem = ({ event }) => {

    return <a href={`/events/${event.id_event}/`} target="_blank" rel="noreferrer">

        <div className="carousel-item  bg-white flex flex-cols items-end  border-x-red-900 border-y-red-900 rounded-2xl border-neutral-800 bg-auto bg-no-repeat"
            style={{
                background: `url(${event.event_ligne?.visuels_1024?.large})`,
                backgroundRepeat: "no-repeat",
                height: '175px',
                backgroundSize: "cover"
            }}>


            <div className=" flex flex-col  w-100 rounded-b-2xl"
                style={{ alignItems: "start", justifyContent: "end" }}>
                <div className="text-white text-sm w-100 rounded-b-2xl font-normal">
                    <div className='bg-neutral-900 p-2 w-100 rounded-b-xl'><i className="far fa-calendar-minus"></i> {event.precision_date} <i className="fas fa-map-marker-alt"></i>  {event.lieu.lieu_nom}</div>
                </div>
            </div>

        </div>
    </a>
}

const Carousel = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [carouselList, setCarouselList] = useState(null)

    const [eventList, setEventList] = useState(null)

    const [isMobile, setIsMobile] = useState(false)
    const [perPage, setPerPage] = useState(1)

    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
            setPerPage("1")
            // console.log("mobile de ouf" + window.innerWidth)
        } else {
            setIsMobile(false)
            setPerPage("2")
        }


    }



    useEffect(() => {

        const getEvents = async () => {


            //const params = 'WHERE highlight_event = 1  AND';

            const params = ` LEFT JOIN event_ligne el ON el.id_event_ligne=events.id_event_ligne

            WHERE
            (el. id_event_format="1")
         
            AND events.id_event NOT IN(399)
            AND afficher !='n'
            AND DATE(event_start) > DATE_SUB(DATE(NOW()), INTERVAL 1 WEEK) AND `;



            //id_event IN(1727, 1765, 1753 )
            await fetch(`${apiUrlTranslation}?action=getEvents&params=  ${params} `)
                .then(res => res.json())
                .then(res => setEventList(res))
        }

        const fetchCarousels = async () => {

            await fetch(`${apiUrlTranslation}?action=getCarousel&filter= WHERE id_carrousel IN(1278, 1279, 1280, 1281, 1282 )`)
                .then(res => res.json())
                .then(res => setCarouselList(res))
        }

        fetchCarousels()
        getEvents()
        window.addEventListener("resize", handleResize)

    }, [isMobile, perPage, i18n.resolvedLanguage])


    if (!carouselList) return false;


    // return <PageSection title={t('sections.carousel.title')} className=" ">
    //     <div className='lg:grid-cols-2 grid grid-cols-1 max-w-6xl items-center mx-auto gap-2 '>

    //         {eventList && eventList.map(event => <CarouselItem event={event} />)}
    //     </div>
    //     <div class="border-b-2  my-4 max-w-2xl mx-auto border-b-neutral-900 mt-5"></div>

    // </PageSection>


    return (
        <PageSection title={t('sections.carousel.title')}>

            <div
                className="container" style={{ height: "200px" }}>
                <Splide
                    options={{
                        pagination: true,
                        type: 'loop',
                        autoplay: true,
                        pauseOnHover: false,
                        resetProgress: false,
                        arrows: 'slider',
                        perPage: isMobile ? 1 : 3,
                        focus: 'center',

                    }}
                    hasSliderWrapper
                    hasAutoplayProgress
                >
                    { /* carouselList && carouselList.map((carousel) =>
                        <SplideSlide key={carousel.id_carrousel} className="mx-1">
                            <a href={carousel.url} target="_blank" rel="noreferrer">
                                <img src={`//www.mlg-consulting.com/manager_cc/docs/archives/${carousel.file}`} alt=""
                                    dataId={carousel.id_carrousel}
                                    style={{
                                        width: '100%'
                                    }} />
                            </a>
                                </SplideSlide>)
                */}


                    {eventList && eventList.map((event) =>
                        <SplideSlide key={event.id_event} className="mx-1">
                            <CarouselItem event={event} />
                        </SplideSlide>)}

                </Splide>
            </div>
        </PageSection >
    )
}

export default Carousel