import React, { useContext, useEffect, useReducer } from 'react';
import parse from 'html-react-parser';
import './RegistrationFormSection.css';
import { capitalize } from '../utils';


const RegistrationFormSection = ({ title, instructions, children, isError, subtitle, supertitle }) => {

    useEffect(() => {

    }, [])


    return <>

        {supertitle}

        <div className={`registration-form-section ${isError ? 'bg-danger bg-opacity-10' : 'bg-white'} rounded p-2 my-4 shadow-sm `}>

            {title && <h4 className="row pl-5  pb-3 text-2xl font-bold">{capitalize(title)}</h4>}

            {subtitle && subtitle}

            {instructions && <div className='alert alert-info'> {instructions}</div>}

            {children}


        </div></>
}

export default RegistrationFormSection;
