import React, { useEffect } from "react";
import CollectifAnnuaireItemContact from "./CollectifAnnuaireItemContact";
import useApiUrlTranslation from "./useApiUrlTranslation";
import HTMLReactParser from "html-react-parser";
import { use } from "i18next";

const CollectifAnnuaireItem = ({ cycle_lang, isChildren }) => {

  const { t } = useApiUrlTranslation();

  useEffect(() => {


  }
    , [])


  return (<>
    <div className={`row border-y border-neutral-100 dark:border-neutral-800 p-3 align-items-center ${isChildren ? "ml-10" : ""}`} key={cycle_lang.id_cycle_lang}>
      <div className="col-md-1">
        <div className="col justify-item flex items-center justify-center">

          <img
            style={{ width: isChildren ? "50px" : "" }}
            src={typeof cycle_lang.logo_better === 'object' ? cycle_lang.logo_better.small : cycle_lang.logo_better_url}
            alt=""
            className={`${cycle_lang.cycle_lang_state == "draft" && 'grayscale'}  rounded `} />
        </div>
      </div>

      <div className="col-md-9">
        <div className="col">
          {cycle_lang.cycle_lang_state == "draft" ? <div className="hidden text-sm bg-orange-100 text-orange-700 rounded-full p-2 w-fit px-3">En cours de développement</div> : ""}
          <p className="font-bold text-xl uppercase dark:text-neutral-100">{cycle_lang.nom_cycle_lang}</p>
          <div className="dark:text-gray-400">{HTMLReactParser(cycle_lang.cycle_lang_descriptif ?? "")}</div>
        </div>
      </div>
      <div className="col-md-3 hidden">
        <div
          style={{ display: "flex", flexWrap: "wrap" }}
          id={`coordinateur_${cycle_lang.id_cycle_lang}`}
          id_cycle_lang={cycle_lang.id_cycle_lang}
        >
          <CollectifAnnuaireItemContact
            id_cycle_lang={cycle_lang.id_cycle_lang}
          />
        </div>
      </div>
      <div className="col-md-2 justify-item flex items-center justify-center ">

        <a
          className={` ${cycle_lang.cycle_lang_categorie == 'continent' ? 'hidden' : ''} inline-flex items-center px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-full  text-gray-800 dark:bg-neutral-400 hover:border-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 `}
          target="_blank"
          rel="noreferrer"
          href={`/collectif-categorie/${cycle_lang.cycle_lang_type}/${cycle_lang.id_cycle_lang}`}
        >
          {t('global.en_savoir_plus')}
        </a>
      </div>
    </div >
    {cycle_lang.children && cycle_lang.children
      //trier par child.nom_cycle_lang
      .sort((a, b) => a.nom_cycle_lang.localeCompare(b.nom_cycle_lang))
      .filter(child => child.cycle_lang_state == 'active')
      .map((child) => <CollectifAnnuaireItem cycle_lang={child} isChildren />)}
  </>
  )
}

export default CollectifAnnuaireItem;