
import HTMLReactParser from 'html-react-parser';
import React from 'react';

import { useFormContext } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { capitalize } from '../utils';
import RegistrationFormInput from './RegistrationFormInput';

const RegistrationFormRow = ({
    labelClassName,
    name,
    placeholder,
    textLabel,
    inputType,
    required = true,
    inputOptions,
    helper,
    isRegister,
    accept,
    defaultValue,
    fieldValue,
    onChange,
    children,
    maxLength
}) => {

    const { t, i18n } = useTranslation();

    const { formState } = useFormContext();

    const [showEditSection, setShowEditSection] = React.useState(false);



    return <div className={`form-group row mb-1 py-2 ${formState.errors[name] ? 'bg-danger  text-dark bg-opacity-10' : ''}`}>

        <label htmlFor={name} className={`${labelClassName} col-sm-3 col-form-label`} >
            {HTMLReactParser(capitalize(textLabel ?? placeholder ?? name))}
            {required && <span className="p-2 text-danger">*</span>}

        </label>

        <div className="col-sm-9">

            {showEditSection ? defaultValue : <>    <RegistrationFormInput

                onChange={onChange}
                defaultValue={defaultValue}
                fieldValue={fieldValue}
                accept={accept}
                isRegister={isRegister}
                inputOptions={inputOptions}
                name={name}
                inputType={inputType}
                required={required}
                maxLength={maxLength}
                placeholder={inputType !== 'textarea' ? capitalize(placeholder) : ''} />
                {formState.errors[name] && <div className="text-danger">{t('form.alerts.veuillez_renseigner')} {capitalize(placeholder)}</div>}
                <small className=" text-gray-400 text-sm">{HTMLReactParser(helper ?? "")}</small>
                {children}
            </>}
        </div>
    </div >
}

export default RegistrationFormRow;

